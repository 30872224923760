<template>
  <a-modal class='select-staff-modal'
           title="选择成员"
           @cancel="$emit('update:visible', false)"
           v-model:visible="modalVisible"
           width="800px"
           okText="保存"
           @ok="saveStaffs">
    <div class="modal-left modal-container">
      <a-input-search class="search-input"
                      v-model:value="searchValue"
                      placeholder="请输入关键字"></a-input-search>
      <a-tree v-model:expandedKeys="expandedKeys"
              v-model:checkedKeys="checkedKeys"
              :show-icon="true"
              @check="handleChecked"
              :tree-data="treeData"
              :replaceFields="replaceDepartDataKey"
              checkable
              :selectable="false"
              @expand="onExpand"
              :auto-expand-parent="autoExpandParent">
        <template #title="{ type, workWechatDeptId, workWechatThirdAppUserId }">
          <div v-is="'ww-open-data'"
               class="ww-open-data"
               :class="lodash.includes(searchValue, workWechatThirdAppUserId) ? 'text-admin-primary' : ''"
               :type="type==='staff' ? 'userName' : 'departmentName'"
               :openid="type==='staff' ? workWechatThirdAppUserId : workWechatDeptId"></div>
        </template>
      </a-tree>
    </div>

    <div class="modal-right modal-container">
      <div class="container-top">
        <span>已选（{{this.staffList.length}}）</span>
        <a-button class="clear-btn"
                  type="link"
                  size="small"
                  @click="clearAll">清空</a-button>
      </div>

      <div v-for="(staff, index) in staffList"
           :key="staff.id"
           class="container-list">
        <div v-is="'ww-open-data'"
             class="ww-open-data"
             type="userName"
             :openid="staff.staffName"></div>
        <span @click="deleteOne(index)">
          <CloseCircleOutlined />
        </span>
      </div>
    </div>
  </a-modal>
</template>

<script>
import _ from "lodash";
import { ref } from "vue";
import { CloseCircleOutlined } from "@ant-design/icons-vue";
import { Tree } from "ant-design-vue";
import staffApi from "@/service/api/staff";

export default {
  name: "selectStaffByGroupModal",
  props: ["visible", "defaultCheckedKeys"],
  components: {
    CloseCircleOutlined,
    ATree: Tree,
  },
  emits: ["update:visible", "saveStaff"],
  setup() {
    const expandedKeys = ref([]);
    const dataList = ref([]);
    const searchValue = ref("");
    const autoExpandParent = ref(true);
    const treeData = ref([]);
    const onExpand = (keys) => {
      expandedKeys.value = keys;
      autoExpandParent.value = false;
    };
    return {
      expandedKeys,
      searchValue,
      autoExpandParent,
      onExpand,
      treeData,
      dataList,
    };
  },
  data() {
    return {
      lodash: _,
      modalVisible: false,
      replaceDepartDataKey: {
        children: "children",
        title: "name",
        key: "id",
      },
      num: 3,
      staffList: [],
      leafNodeList: [],
      checkedKeys: [],
    };
  },
  computed: {},
  async mounted() {
    await this.getAllDepartments();
    this.initDataList();
    this.initDefaultCheckedValue();
  },
  watch: {
    visible(value) {
      this.modalVisible = value;
      if (value) {
        this.checkedKeys = this.defaultCheckedKeys;
        this.initDefaultCheckedValue();
      }
    },
    searchValue(value) {
      this.expandedKeys = [];
      this.backupsExpandedKeys = [];

      if (value === "") {
        this.searchValue = value;
        this.autoExpandParent = true;
        return;
      }
      const expanded = this.dataList
        .map((item) => {
          if (item.title.indexOf(value) > -1) {
            return this.getParentKey(item.key, this.treeData);
          }
          return null;
        })
        .filter((item, i, self) => item && self.indexOf(item) === i);
      this.autoExpandParent = true;
      this.expandedKeys = expanded;
      this.searchValue = value;
    },
  },
  methods: {
    async getAllDepartments() {
      this.treeData = await staffApi.getStaffSearchListWithDept();
    },
    getLeafNodeList(treedata) {
      const data = treedata;
      _.forEach(data, (node) => {
        if (node.type === "dept") {
          if (node?.children?.length > 0) {
            this.getLeafNodeList(node.children);
          }
        } else {
          this.leafNodeList.push(node);
        }
      });
    },
    initDefaultCheckedValue() {
      this.leafNodeList = [];
      this.staffList = [];

      this.getLeafNodeList(this.treeData);
      _.forEach(this.leafNodeList, (leafNode) => {
        if (this.checkedKeys.includes(leafNode.id)) {
          const params = {
            staffId: leafNode.id,
            staffName: leafNode.name,
          };
          this.staffList.push(params);
        }
      });
    },
    initDataList() {
      this.dataList = [];
      this.generateList(this.treeData);
    },
    generateList(data) {
      for (let i = 0; i < data.length; i++) {
        const node = data[i];
        this.dataList.push({
          key: node.id,
          title: node.name,
        });

        if (node.children) {
          this.generateList(node.children);
        }
      }
    },
    getParentKey(key, tree) {
      let parentKey, temp;
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
          if (node.children.some((item) => item.id === key)) {
            parentKey = node.id;
          } else if ((temp = this.getParentKey(key, node.children))) {
            parentKey = temp;
            parentKey = this.getParentKey(key, node.children);
          }
        }
      }
      return parentKey;
    },
    handleChecked(checkedKeys, event) {
      this.staffList = [];
      _.forEach(event.checkedNodes, (checkedNode) => {
        console.log(checkedNode.props.dataRef);
        const {
          id: staffId,
          name: staffName,
          workWechatThirdAppUserId,
        } = checkedNode.props.dataRef;
        if (_.split(staffId, "_")[0] === "staff") {
          this.staffList.push({ staffId, staffName, workWechatThirdAppUserId });
        }
      });
    },
    clearAll() {
      this.staffList = [];
      this.checkedKeys = [];
    },
    deleteOne(index) {
      this.staffList.splice(index, 1);

      const checkedIds = [];
      _.forEach(this.staffList, (staff) => {
        checkedIds.push(staff.staffId);
      });
      this.checkedKeys = checkedIds;
    },
    saveStaffs() {
      this.$emit("saveStaff", _.slice(this.staffList, 0));
    },
  },
};
</script>