<template>
  <a-form :model="form"
          :labelCol="{style: 'width: 96px'}">

    <a-form-item label="讲师设置"
                 required>

      <a-radio-group v-model:value="form.teacher.teacherSource"
                     @change="handleTeacherSourceChange">
        <a-radio value="internal">内部讲师</a-radio>
        <a-radio value="external">外部讲师</a-radio>
      </a-radio-group>

      <div v-if="form.teacher.teacherSource == 'internal'">
        <a-popover trigger="click"
                   placement="right"
                   :getPopupContainer="() => $refs.addTeacherBtn"
                   :overlayStyle="{maxHeight: '300px'}">
          <template #content>
            <select-staff @choose="chooseInternalTeacher" />
          </template>
          <div ref="addTeacherBtn"
               class="w-max">
            <a-button>
              <PlusOutlined v-if="!form.teacher.teacherId" />
              {{ form.teacher?.teacherId ? '重新选择讲师' : '添加讲师' }}
            </a-button>
          </div>
        </a-popover>

        <p v-if="form.teacher.teacherId"
           class="text-color-999">
          已选择 <span class="font-medium text-admin-primary">
            「
            <div v-is="'ww-open-data'"
                 class="ww-open-data"
                 type="userName"
                 :openid="form.teacher.workWechatThirdAppUserId"></div>
            」
          </span> 为讲师
        </p>
      </div>

      <div v-else
           class="text-color-999">
        <template v-if="!form.teacher.teacherId">
          <svg-icon class="mr-4 text-orange-4"
                    type="icontishi" />
          将以下链接发送到讲师微信，讲师确认后即邀请成功。
          <div class="flex items-end">
            <div v-html="liveInviteLink"
                 class="p-16 w-max text-color-666 bg-color-f5">
            </div>
            <span class="inline-block ml-12 text-admin-primary cursor-pointer"
                  @click="copy(liveInviteLink)">复制链接</span>
          </div>

        </template>

        <template v-else>
          已邀请 <span class="font-medium text-admin-primary">「 {{ form.teacher.name }} 」</span> 为讲师
          <a-button class="ml-16"
                    type="link"
                    @click="showDeleteTeacherConfirm">重新设置讲师</a-button>
        </template>
      </div>
    </a-form-item>

    <a-form-item label="助教设置"
                 name="assistantIds"
                 :wrapperCol="{span: 16}">
      <a-button @click="selectStaffModalVisible = true">
        <PlusOutlined />添加助教
      </a-button>

      <div v-if="form.assistants.length"
           class="flex flex-wrap mt-8">
        <a-tag v-for="staff in form.assistants"
               :key="staff.id"
               class="flex justify-between items-center flex-nowrap px-12 py-4 w-96 h-28"
               closable
               @close="deleteAssistant(staff)">
          <div v-is="'ww-open-data'"
               class="ww-open-data line-clamp-1 w-48"
               type="userName"
               :openid="staff.workWechatThirdAppUserId"></div>
        </a-tag>
      </div>
    </a-form-item>

    <select-staff-modal v-model:visible="selectStaffModalVisible"
                        :defaultCheckedKeys="checkedKeys"
                        @saveStaff="saveStaff" />

    <a-form-item label="课件设置"
                 :wrapperCol="{flex: 1}">
      <upload-lesson :data="classFileList"
                     :room-id="liveRoom.id"
                     @update:data="handleLessonStatusChange" />

      <a-table v-show="classData.length"
               :dataSource="classData"
               :columns="classColumns"
               row-key="uid"
               bordered
               :pagination="false">

        <template #status="{ record, text }">
          <span :class="{'text-danger': lodash.includes(text, 'error')}">
            {{ text === 'error' ? ( record.error ? `上传失败, ${record.error}` : '上传失败') :  transformStatus[text].text }}

          </span>
        </template>

        <template #action="{ record }">
          <a-button class="text-color-999"
                    type="link"
                    :disabled="record.status === 'uploading'"
                    @click="handleDeleteLesson(record)">删除</a-button>
        </template>
      </a-table>

      <p class="mt-8 text-12 text-color-999">限ppt或pptx格式课件，大小不超过20M。</p>
    </a-form-item>

    <a-form-item class="relative ant-form-item__trailer"
                 style="line-height: 1.5715">
      <template #label>
        预告片
        <a-popover placement="right">
          <template #content>
            开播前，观众可以通过视频了解课程信息。
          </template>
          <svg-icon class="text-icon"
                    type="iconyiwen" />
        </a-popover>
      </template>

      <div class="trailer__show relative">
        <template v-if="trailerFile[0].uid && trailerData.status === 'ok'">
          <video ref="video_player"
                 controls
                 controlslist="noplaybackrate"
                 disablePictureInPicture
                 loop
                 width="320"
                 height="180">
            Your browser does not support the video tag.
          </video>
          <svg-icon class="icon-bianji hidden absolute -top-4 -right-4 text-16 text-icon cursor-pointer"
                    type="iconguanbi"
                    @click="deleteTrailerModalVisible = true" />
        </template>

        <div v-show="trailerFile[0].uid && trailerData.status !== 'ok'"
             class="relative">
          <img class="absolute top-0 left-0 w-full h-full z-0"
               :src="liveRoom.liveCover"
               alt="直播封面底层">

          <div class="flex justify-center items-center relative bg-black opacity-70  border-radius"
               :class="lodash.includes(trailerData.status, 'error') ? 'text-danger' : 'text-color-f'"
               style="width: 320px; height: 180px;">

            <lottie v-if="trailerData.status === 'uploading'"
                    :options="{animationData: uploadingGif}"
                    :width="32"
                    :height="32"
                    :style="{position: 'relative', margin: 0}">
            </lottie>

            <lottie v-else-if="trailerData.status === 'transcoding'"
                    :options="{animationData: transcodingGif}"
                    :width="32"
                    :height="32"
                    :style="{position: 'relative', margin: 0}">
            </lottie>

            <svg-icon v-else
                      style="font-size: 32px;"
                      :type="transformStatus[trailerData.status]?.icon" />
            <span class="inline-block text-18 ml-12">{{ transformStatus[trailerData.status]?.text }}</span>
          </div>

          <svg-icon v-show="'uploading' !== trailerData.status"
                    class="icon-bianji hidden absolute -top-4 -right-4 text-16 text-icon cursor-pointer"
                    type="iconguanbi"
                    @click="handleDeleteTrailer" />
        </div>

        <upload-video v-model:data="trailerFile"
                      @update:data="handleTrailerStatusChange" />

      </div>
    </a-form-item>

    <delete-modal v-model:visible="deleteLessonModalVisible"
                  title="删除提醒"
                  desc="确认是否删除该课件，删除后无法恢复"
                  @delete="deleteLesson">
    </delete-modal>

    <delete-modal v-model:visible="deleteTrailerModalVisible"
                  title="删除提醒"
                  desc="确认是否删除预告片，删除后无法恢复"
                  @delete="deleteTrailer">
    </delete-modal>

  </a-form>

</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  watch,
  computed,
  onBeforeUnmount,
  h,
} from "vue";
import _ from "lodash";
import { onBeforeRouteLeave, useRoute } from "vue-router";
import { Radio, Popover, message, Modal } from "ant-design-vue";
import Hls from "hls.js";

import lottie from "vue-lottie";
import uploadingGif from "@/assets/uploading.json";
import transcodingGif from "@/assets/transcoding.json";

import SelectStaff from "@/components/SelectStaff";
import SelectStaffModal from "@/components/SelectStaffByGroup";
import SvgIcon from "@/components/SvgIcon";
import UploadVideo from "@/views/liveOpenClass/statictisSubpages/UploadVideo";
import UploadLesson from "@/views/liveOpenClass/statictisSubpages/UploadLesson";
import DeleteModal from "@/components/DeleteModal";

import liveApi from "@/service/api/live";
import { copy } from "@/global";

export default defineComponent({
  name: "liveStatisticClassSetting",

  props: {
    liveRoom: {
      type: Object,
      default: () => {},
      required: true,
    },
    config: {
      type: [Array, String],
      default: () => [],
    },
  },

  components: {
    ARadio: Radio,
    ARadioGroup: Radio.Group,
    APopover: Popover,

    SelectStaff,
    SelectStaffModal,
    SvgIcon,
    UploadVideo,
    UploadLesson,
    DeleteModal,
    lottie,
  },

  setup(props, { emit }) {
    const route = new useRoute();

    const { id: liveRoomId } = route.query;

    const liveInviteLink = ref("");

    const form = reactive({
      teacher: {
        teacherSource: "",
        teacherId: "",
        name: "",
        workWechatThirdAppUserId: "",
      },
      assistants: [],
    });

    const transformStatus = {
      waiting: { icon: "iconzhuanma", text: "待转码" },
      transcoding: { icon: "iconzhuanma", text: "转码中..." },
      ok: { icon: "iconzhuanma", text: "转码完成" },
      transcoding_error: { icon: "iconzhuanmashibai", text: "转码失败" },

      uploading: { icon: "iconshangchuan", text: "上传中..." },
      done: { icon: "iconshangchuan", text: "上传成功" },
      error: { icon: "iconfujian-shangchuanshibai", text: "上传失败" },

      none: { icon: "iconshangchuan", text: "无状态" },
    };

    // 初始化数据
    watch(
      () => props.liveRoom?.id,
      () => {
        const { externalTicket, corpId, id } = props.liveRoom;

        if (!id) {
          return;
        }

        liveInviteLink.value = `https://${window.location.host}/customer-h5/live/invite?externalTicket=${externalTicket}&corpId=${corpId}`;

        initTeacher();
        initAssistantsId();
        initLesson();
        initTrailer();
      }
    );

    const initTeacher = function () {
      const { teacherId, teacherSource, teacher } = props.liveRoom;

      if (teacher) {
        _.assign(form.teacher, {
          teacherId,
          name: teacher.name,
          teacherSource,
          workWechatThirdAppUserId: teacher.workWechatThirdAppUserId,
        });
        return;
      }

      _.assign(form.teacher, { teacherId, teacherSource });
    };

    const initAssistantsId = function () {
      if (props.liveRoom?.assistants?.length) {
        form.assistants = _.cloneDeep(props.liveRoom?.assistants);
      }
    };

    const initLesson = async () => {
      const res = await liveApi.getAllCourseware({
        roomId: liveRoomId,
      });

      const lessonList = _.map(res, (lesson) => {
        const {
          resourcesNo: no,
          fileStatus: status,
          fileName: name,
          id,
        } = lesson;

        return { uid: no, no, status, name, id };
      });

      classData.value = lessonList;
      classFileList.value = lessonList;
    };

    const initTrailer = async () => {
      if (!props.liveRoom.trailer) {
        return;
      }

      const res = await liveApi.getVideoStatus({
        roomId: liveRoomId,
      });

      if (!res || _.isEmpty(res)) {
        _.assign(trailerData, { status: "none" });
        _.assign(trailerFile.value[0], { status: "none" });
        return;
      }

      const { no, processStatus: status } = res;
      _.assign(trailerData, { uid: no, no, status });
      _.assign(trailerFile.value[0], { uid: no, no, status });

      if (status === "transcoding_error") {
        return;
      }

      if (status === "ok") {
        initHls();
        return;
      }

      if (_.includes(["waiting", "transcoding"], status)) {
        trailerInterval();
      }
    };

    // 讲师设置
    const chooseInternalTeacher = async (selectStaff) => {
      // 判断当前讲师是否为助教，若是则报错
      const isAssistant = _.some(form.assistants, (assistant) => {
        return assistant.id == selectStaff.id;
      });

      if (isAssistant) {
        message.error("该员工已经是助教了，请重新选择");
        return;
      }

      if (form.teacher.teacherSource === "external") {
        await deleteExternalTeacher();
      }

      const { id: teacherId, staffName: name } = selectStaff;

      _.assign(form.teacher, selectStaff);

      updateTeacher({
        teacherId,
        name,
        teacherSource: "internal",
      });
    };

    const handleTeacherSourceChange = () => {
      if (form.teacher.teacherSource === "internal") {
        return;
      }

      form.teacher = {
        teacherSource: "external",
        teacherId: null,
        name: null,
      };

      updateTeacher(form.teacher);
    };

    const updateTeacher = async (teacher) => {
      const { teacherId, teacherSource } = teacher;

      await liveApi.updateTeacher({
        id: liveRoomId,
        teacherId,
        teacherSource,
      });

      emit("changeTeacher", teacherId);

      message.success("保存成功");

      _.assign(form.teacher, teacher);
    };

    const deleteExternalTeacher = async () => {
      await liveApi.resetExternalTeacher({
        id: liveRoomId,
      });

      _.assign(form.teacher, {
        teacherId: null,
        name: null,
      });
    };

    const showDeleteTeacherConfirm = () => {
      Modal.confirm({
        title: "重新设置讲师",

        content: h(
          "p",
          { class: "text-color-999" },
          "确定要重新设置讲师？点击「确定」后删除当前讲师，并生成新的邀请链接。"
        ),
        class: "confirm-modal",
        async onOk() {
          await deleteExternalTeacher().catch(() => {
            return Promise.reject();
          });

          return Promise.resolve();
        },

        onCancel() {},
      });
    };

    // 助教设置
    const selectStaffModalVisible = ref(false);

    const assistantIds = computed(() => {
      return _.map(form.assistants, "id");
    });

    const checkedKeys = computed(() => {
      return _.map(form.assistants, (staff) => "staff_" + staff.id);
    });

    const saveStaff = async (list) => {
      const theStaffIsTeacher = _.find(list, (staff) => {
        return `staff_${form.teacher.teacherId}` == staff.staffId;
      });

      if (_.keys(theStaffIsTeacher).length) {
        message.error(`${theStaffIsTeacher.staffName}已经是讲师了，请重新选择`);
        return;
      }

      form.assistants = _.map(list, (staff) => {
        const id = _.split(staff.staffId, "_")[1];
        return {
          id,
          name: staff.staffName,
          workWechatThirdAppUserId: staff.workWechatThirdAppUserId,
        };
      });

      await liveApi.updateAssistant({
        id: liveRoomId,
        assistantIds: assistantIds.value,
      });

      message.success("保存成功");

      selectStaffModalVisible.value = false;
    };

    const deleteAssistant = async (deleteStaff) => {
      _.remove(form.assistants, (staff) => staff.id == deleteStaff.id);

      await liveApi.updateAssistant({
        id: liveRoomId,
        assistantIds: assistantIds.value,
      });

      message.success("删除成功");
    };

    // 上传课件和预告片通用方法
    const saveAppendix = async (no, type, file, lessonParams = {}) => {
      const typeEnum = {
        trailer: "saveTrailer",
        lesson: "saveCourseware",
      };

      if (type === "trailer") {
        await liveApi[typeEnum[type]]({
          roomId: liveRoomId,
          no,
        });
        return;
      }

      const res = await liveApi[typeEnum[type]]({
        roomId: liveRoomId,
        no,
        ...lessonParams,
      });

      if (type === "lesson") {
        const { id, fileName: name, fileStatus: status, resourcesNo: no } = res;
        savedLessonNoList.value.push(no);
        _.assign(file, { id, name, status, no });
      }
    };

    // 课件设置
    const classColumns = [
      {
        title: "文件名",
        dataIndex: "name",
      },
      {
        title: "状态",
        dataIndex: "status",
        slots: {
          customRender: "status",
        },
      },
      {
        title: "操作",
        dataIndex: "action",
        slots: {
          customRender: "action",
        },
      },
    ];

    const classData = ref([]);

    const classFileList = ref([]);

    const savedLessonNoList = ref([]);

    const transcodingLessonList = computed(() => {
      return _.filter(classData.value, (lesson) => {
        return _.includes(["waiting", "transcoding"], lesson.status);
      });
    });

    const handleLessonStatusChange = (files) => {
      if (!files.length) {
        return;
      }

      _.forEach(files, (file) => {
        const { uid, status, name, response, error } = file;
        let lesson = _.find(classData.value, (lesson) => {
          return lesson.uid === uid;
        });

        if (!lesson) {
          let lesson = { uid, status, name };
          classData.value.push(lesson);
        }

        if (status === "error") {
          lesson.error = error;
        }

        if (status === "done") {
          if (response.uploaded) {
            _.assign(response, response.content);
          }

          const { id: contentId, resNo: no, status } = response;
          _.assign(lesson, { uid, status, no, name });

          if (!_.includes(savedLessonNoList.value, no)) {
            saveAppendix(no, "lesson", lesson, {
              contentId,
              fileName: name,
              fileStatus: status,
            });
          }
        }
      });
    };

    watch(
      () => [...transcodingLessonList.value],
      (list, oldList) => {
        if (list.length && !oldList.length) {
          lessonInterval();
          return;
        }

        if (!list.length && oldList.length) {
          clearInterval(lessonTimer.value);
          lessonTimer.value = null;
        }
      }
    );

    const lessonTimer = ref();

    const lessonInterval = () => {
      lessonTimer.value = setInterval(async () => {
        const lessonList = await liveApi.getAllCourseware({
          roomId: liveRoomId,
        });

        _.forEach(lessonList, (lesson) => {
          const { fileStatus: status, id } = lesson;

          const findLesson = _.find(classData.value, (item) => item.id === id);
          _.assign(findLesson, { status });
        });
      }, 5000);
    };

    const deleteLessonModalVisible = ref(false);
    const deleteItem = reactive({
      id: "",
    });

    const handleDeleteLesson = (lesson) => {
      const { id, no, uid, status } = lesson;
      if (status === "error") {
        _.remove(classData.value, (lesson) => lesson.uid === uid);
        return;
      }

      deleteLessonModalVisible.value = true;
      deleteItem.id = id;
      deleteItem.no = no;
    };

    const deleteLesson = async () => {
      await liveApi.deleteCourseware({ ...deleteItem }).finally(() => {
        deleteLessonModalVisible.value = false;
        confirmLoading.value = false;
      });

      _.remove(classData.value, (lesson) => {
        return lesson.id === deleteItem.id;
      });
      _.remove(savedLessonNoList.value, (no) => {
        return no === deleteItem.no;
      });
    };

    // 预告片
    const trailerFile = ref([{ status: "none" }]);
    const trailerData = reactive({ status: "none" });

    const clearTrailer = () => {
      trailerFile.value = [{ status: "none" }];
      _.assign(trailerData, { uid: null, status: "", no: "" });
    };

    const handleTrailerStatusChange = (file) => {
      if (!file.length) {
        clearTrailer();
        return;
      }
      const { uid, status, response: no } = file[0];
      _.assign(trailerData, { uid, status, no });

      if (status === "done") {
        saveAppendix(no, "trailer", trailerData);

        // 上传完成，轮询查询转码状态
        trailerData.status = "waiting";
        trailerInterval();
      }
    };

    const getStatusByResNo = async (no) => {
      const res = await liveApi.getConvertStatus({ no });
      return res.processStatus;
    };

    const trailerTimer = ref();

    const trailerInterval = () => {
      trailerTimer.value = setInterval(async () => {
        const { no } = trailerData;
        const status = await getStatusByResNo(no);

        trailerData.status = status;

        if (status === "transcoding_error") {
          return;
        }

        if (status === "ok") {
          initHls();

          clearInterval(trailerTimer.value);
          trailerTimer.value = null;
        }
      }, 5000);
    };

    // 引入hls.js
    const video_player = ref();
    const video_url = ref("");

    const getVideoUrl = async () => {
      const trailerParams = await liveApi.getTrailerPlayUrl({
        resNo: trailerData.no,
      });
      const playlist = trailerParams.args?.playlist;
      const playlistLength = playlist.length;
      video_url.value = playlist[playlistLength - 1]?.url;
    };
    const hls = ref();
    const initHls = async () => {
      await getVideoUrl();

      if (!Hls.isSupported()) {
        video_player.value.setAttribute("src", video_url.value);
        return;
      }

      const hlsInstance = new Hls();
      hls.value = hlsInstance;

      hlsInstance.attachMedia(video_player.value);

      hlsInstance.loadSource(video_url.value);

      hlsInstance.on(Hls.Events.ERROR, function (event, data) {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              console.warn("fatal network error encountered, try to recover");
              hlsInstance.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              console.warn("fatal media error encountered, try to recover");
              hlsInstance.recoverMediaError();
              break;
            default:
              hlsInstance.destroy();
              break;
          }
        }
      });
    };

    const deleteTrailerModalVisible = ref(false);

    const confirmLoading = ref(false);

    const handleDeleteTrailer = () => {
      if (trailerData.status === "error") {
        trailerFile.value = [{ status: "none" }];
        trailerData.status = "none";
        return;
      }

      deleteTrailerModalVisible.value = true;
    };

    const deleteTrailer = async () => {
      confirmLoading.value = true;
      await liveApi
        .deleteTrailer({
          id: liveRoomId,
        })
        .finally(() => {
          deleteTrailerModalVisible.value = false;
          confirmLoading.value = false;
        });

      trailerFile.value = [{ status: "none" }];
      trailerData.status = "none";

      if (trailerTimer.value) {
        clearInterval(trailerTimer.value);
        trailerTimer.value = null;
      }

      if (hls.value) {
        hls.value.destroy();
      }
    };

    onBeforeRouteLeave(() => {
      if (trailerData.status === "uploading") {
        message.info("预告片已取消上传");
      }
    });

    onBeforeUnmount(() => {
      if (hls.value) {
        hls.value.destroy();
      }

      if (lessonTimer.value) {
        clearInterval(lessonTimer.value);
        lessonTimer.value = null;
      }

      if (trailerTimer.value) {
        clearInterval(trailerTimer.value);
        trailerTimer.value = null;
      }
    });

    return {
      form,
      lodash: _,
      liveInviteLink,
      copy,

      selectStaffModalVisible,
      checkedKeys,
      saveStaff,
      deleteAssistant,
      chooseInternalTeacher,
      handleTeacherSourceChange,
      showDeleteTeacherConfirm,

      transformStatus,

      classData,
      classFileList,
      classColumns,
      handleLessonStatusChange,
      deleteLessonModalVisible,
      handleDeleteLesson,
      deleteLesson,

      deleteTrailerModalVisible,
      trailerFile,
      trailerData,
      handleTrailerStatusChange,
      video_player,

      uploadingGif,
      transcodingGif,

      confirmLoading,
      handleDeleteTrailer,
      deleteTrailer,
    };
  },
});
</script>
<style lang='less' scoped>
.ant-radio-wrapper {
  &:not(:last-child) {
    margin-right: 44px;
  }
}

.ant-form-item__trailer {
  :deep(.ant-form-item-control) {
    position: relative;
    line-height: initial;
  }
}

.trailer__show {
  &:hover {
    .icon-bianji {
      display: initial;
    }
  }
}

//观看的当前时间
video::-webkit-media-controls-current-time-display {
  display: none;
}
//剩余时间
video::-webkit-media-controls-time-remaining-display {
  display: none;
}

video::-webkit-media-controls-volume-control-container {
  display: none;
}
//音量按钮
video::-webkit-media-controls-mute-button {
  display: none;
}
video::-webkit-media-controls-toggle-closed-captions-button {
  display: none;
}
//音量的控制条
video::-webkit-media-controls-volume-slider {
  display: none;
}
</style>